@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	@apply h-screen;
	@apply bg-gray-50;
}
body {
	@apply h-screen;
}
#root {
	@apply h-full;
}
